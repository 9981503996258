import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Sidebar from '../../../Components/Sidebar'; // optional if you have a sidebar
import getEnvConfig from '../../../config';
import { fetchToken } from '../../../Auth'; // if you need a token from local storage

// A simple states array. Or you can store them in your code/DB.
const US_STATES = [
    { code: 'AL', name: 'Alabama' },
    { code: 'AK', name: 'Alaska' },
    { code: 'AZ', name: 'Arizona' },
    { code: 'AR', name: 'Arkansas' },
    { code: 'CA', name: 'California' },
    { code: 'CO', name: 'Colorado' },
    { code: 'CT', name: 'Connecticut' },
    { code: 'DE', name: 'Delaware' },
    { code: 'FL', name: 'Florida' },
    { code: 'GA', name: 'Georgia' },
    { code: 'HI', name: 'Hawaii' },
    { code: 'ID', name: 'Idaho' },
    { code: 'IL', name: 'Illinois' },
    { code: 'IN', name: 'Indiana' },
    { code: 'IA', name: 'Iowa' },
    { code: 'KS', name: 'Kansas' },
    { code: 'KY', name: 'Kentucky' },
    { code: 'LA', name: 'Louisiana' },
    { code: 'ME', name: 'Maine' },
    { code: 'MD', name: 'Maryland' },
    { code: 'MA', name: 'Massachusetts' },
    { code: 'MI', name: 'Michigan' },
    { code: 'MN', name: 'Minnesota' },
    { code: 'MS', name: 'Mississippi' },
    { code: 'MO', name: 'Missouri' },
    { code: 'MT', name: 'Montana' },
    { code: 'NE', name: 'Nebraska' },
    { code: 'NV', name: 'Nevada' },
    { code: 'NH', name: 'New Hampshire' },
    { code: 'NJ', name: 'New Jersey' },
    { code: 'NM', name: 'New Mexico' },
    { code: 'NY', name: 'New York' },
    { code: 'NC', name: 'North Carolina' },
    { code: 'ND', name: 'North Dakota' },
    { code: 'OH', name: 'Ohio' },
    { code: 'OK', name: 'Oklahoma' },
    { code: 'OR', name: 'Oregon' },
    { code: 'PA', name: 'Pennsylvania' },
    { code: 'RI', name: 'Rhode Island' },
    { code: 'SC', name: 'South Carolina' },
    { code: 'SD', name: 'South Dakota' },
    { code: 'TN', name: 'Tennessee' },
    { code: 'TX', name: 'Texas' },
    { code: 'UT', name: 'Utah' },
    { code: 'VT', name: 'Vermont' },
    { code: 'VA', name: 'Virginia' },
    { code: 'WA', name: 'Washington' },
    { code: 'WV', name: 'West Virginia' },
    { code: 'WI', name: 'Wisconsin' },
    { code: 'WY', name: 'Wyoming' },
  ];
  

const ManageIncentives = () => {
  const { backendUrl } = getEnvConfig();
  const token = fetchToken(); // if needed for auth

  // UI state
  const [selectedState, setSelectedState] = useState('');
  const [incentives, setIncentives] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // For creating a new incentive
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newIncentive, setNewIncentive] = useState({
    state: '',
    incentive_name: '',
    incentive_description: '',
    talking_points: '',
    is_active: true,
    provider_link: ''
  });

  // 1) Handle the user selecting a state from dropdown
  const handleStateChange = (e) => {
    const code = e.target.value;
    setSelectedState(code);
    setIncentives([]); // reset existing
    setError(null);
  };

  // 2) Fetch incentives for selected state
  const fetchIncentives = async () => {
    if (!selectedState) return;
    setLoading(true);
    setError(null);
    setIncentives([]);

    try {
      const response = await axios.get(`${backendUrl}/incentives/${selectedState}`, {
        headers: { Authorization: `Bearer ${token}` } // if needed
      });
      setIncentives(response.data || []);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        // "No incentives found" or 404
        setIncentives([]);
        setError('No incentives found for this state.');
      } else {
        setError(err.message || 'Error fetching incentives');
      }
    } finally {
      setLoading(false);
    }
  };

  // 3) Update an incentive in local state, e.g. on input change
  const handleIncentiveChange = (idx, field, value) => {
    const updated = [...incentives];
    updated[idx] = { ...updated[idx], [field]: value };
    setIncentives(updated);
  };

// 4) Save (PUT) a single incentive
const handleSaveIncentive = async (idx) => {
    const incentive = incentives[idx];
    try {
      // Instead of sending "...incentive, user_token: token" as a top-level object
      // We now match the Pydantic model exactly:
      await axios.put(`${backendUrl}/incentives/${incentive.incentive_id}`, {
        user_token: token,
        state: incentive.state,
        incentive_name: incentive.incentive_name,
        incentive_description: incentive.incentive_description,
        talking_points: incentive.talking_points,
        is_active: incentive.is_active,
        provider_link: incentive.provider_link,
      });
      alert('Incentive updated successfully');
    } catch (error) {
      console.error('Error updating incentive:', error);
      alert('Error updating incentive');
    }
  };

// 5) Delete an incentive
const handleDeleteIncentive = async (incentive_id, idx) => {
    if (!window.confirm('Are you sure you want to delete this incentive?')) return;
  
    try {
      await axios.delete(`${backendUrl}/incentives/${incentive_id}`, {
        data: {
          user_token: token
        },
        headers: {
          "Content-Type": "application/json"
        }
      });
      alert('Incentive deleted successfully');
  
      const updated = [...incentives];
      updated.splice(idx, 1);
      setIncentives(updated);
    } catch (error) {
      console.error('Error deleting incentive:', error);
      alert('Error deleting incentive');
    }
  };

  // 6) For creating a new incentive
  const handleCreateIncentive = async () => {
    try {
      if (!newIncentive.state) {
        return alert('Please select a valid state for the new incentive.');
      }
      await axios.post(`${backendUrl}/incentives/`, {
        ...newIncentive,
        user_token: token,
      }
      
    );
      alert('Incentive created successfully');
      setShowCreateForm(false);
      // Optionally re-fetch
      if (selectedState === newIncentive.state) {
        fetchIncentives();
      }
    } catch (error) {
      console.error('Error creating incentive:', error);
      alert('Error creating incentive');
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar /> {/* remove if you don't need a sidebar */}
      <Container fluid style={{ marginLeft: '5px' }}>
        <Row className="justify-content-center my-5">
          <Col md={10}>
            <h2 className="mb-4 text-center">Manage State Incentives</h2>

            {/* 1) SELECT A STATE & BUTTON TO FETCH */}
            <Form.Group controlId="stateSelect" className="mb-3">
              <Form.Label>Select State</Form.Label>
              <Form.Control as="select" value={selectedState} onChange={handleStateChange}>
                <option value="">-- Select State --</option>
                {US_STATES.map((st) => (
                  <option key={st.code} value={st.code}>
                    {st.code} - {st.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Button variant="primary" onClick={fetchIncentives} disabled={!selectedState}>
              Fetch Incentives
            </Button>

            {loading && <p className="mt-3">Loading incentives...</p>}
            {error && (
              <p className="mt-3 text-danger">
                {error}
              </p>
            )}

            {/* 2) LIST INCENTIVES */}
            {incentives.map((incentive, idx) => (
              <Card key={incentive.incentive_id} className="mt-4">
                <Card.Body>
                  <Card.Title>Incentive ID: {incentive.incentive_id}</Card.Title>

                  {/* State */}
                  <Form.Group controlId={`state_${incentive.incentive_id}`} className="mb-2">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      value={incentive.state}
                      onChange={(e) => handleIncentiveChange(idx, 'state', e.target.value)}
                    />
                  </Form.Group>

                  {/* incentive_name */}
                  <Form.Group controlId={`name_${incentive.incentive_id}`} className="mb-2">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={incentive.incentive_name}
                      onChange={(e) => handleIncentiveChange(idx, 'incentive_name', e.target.value)}
                    />
                  </Form.Group>

                  {/* incentive_description */}
                  <Form.Group controlId={`desc_${incentive.incentive_id}`} className="mb-2">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={incentive.incentive_description}
                      onChange={(e) => handleIncentiveChange(idx, 'incentive_description', e.target.value)}
                    />
                  </Form.Group>

                  {/* talking_points */}
                  <Form.Group controlId={`talking_${incentive.incentive_id}`} className="mb-2">
                    <Form.Label>Talking Points (pipe-separated)</Form.Label>
                    <Form.Control
                      type="text"
                      value={incentive.talking_points || ''}
                      onChange={(e) => handleIncentiveChange(idx, 'talking_points', e.target.value)}
                    />
                  </Form.Group>

                  {/* is_active as radio buttons */}
                  <Form.Group>
                    <Form.Label>Is Active?</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name={`active_${incentive.incentive_id}`}
                        checked={incentive.is_active === true}
                        onChange={() => handleIncentiveChange(idx, 'is_active', true)}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name={`active_${incentive.incentive_id}`}
                        checked={incentive.is_active === false}
                        onChange={() => handleIncentiveChange(idx, 'is_active', false)}
                      />
                    </div>
                  </Form.Group>

                  {/* provider_link */}
                  <Form.Group controlId={`link_${incentive.incentive_id}`} className="mb-2 mt-2">
                    <Form.Label>Provider Link</Form.Label>
                    <Form.Control
                      type="text"
                      value={incentive.provider_link || ''}
                      onChange={(e) => handleIncentiveChange(idx, 'provider_link', e.target.value)}
                    />
                  </Form.Group>

                  <div className="mt-3">
                    <Button variant="success" onClick={() => handleSaveIncentive(idx)} className="me-2">
                      Save
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteIncentive(incentive.incentive_id, idx)}>
                      Delete
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}

            {/* 3) CREATE NEW INCENTIVE */}
            <div className="mt-5">
              <Button onClick={() => setShowCreateForm(!showCreateForm)}>
                {showCreateForm ? 'Close Create Form' : 'Create New Incentive'}
              </Button>
              {showCreateForm && (
                <Card className="mt-3">
                  <Card.Body>
                    <Form.Group controlId="newState" className="mb-2">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        value={newIncentive.state}
                        onChange={(e) =>
                          setNewIncentive({ ...newIncentive, state: e.target.value.toUpperCase() })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="newName" className="mb-2">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={newIncentive.incentive_name}
                        onChange={(e) =>
                          setNewIncentive({ ...newIncentive, incentive_name: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="newDesc" className="mb-2">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={newIncentive.incentive_description}
                        onChange={(e) =>
                          setNewIncentive({ ...newIncentive, incentive_description: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="newTalking" className="mb-2">
                      <Form.Label>Talking Points (pipe-separated)</Form.Label>
                      <Form.Control
                        type="text"
                        value={newIncentive.talking_points}
                        onChange={(e) =>
                          setNewIncentive({ ...newIncentive, talking_points: e.target.value })
                        }
                      />
                    </Form.Group>

                    {/* is_active as radio */}
                    <Form.Group>
                      <Form.Label>Is Active?</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          name="new_active"
                          checked={newIncentive.is_active === true}
                          onChange={() => setNewIncentive({ ...newIncentive, is_active: true })}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="new_active"
                          checked={newIncentive.is_active === false}
                          onChange={() => setNewIncentive({ ...newIncentive, is_active: false })}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId="newLink" className="mb-2 mt-2">
                      <Form.Label>Provider Link</Form.Label>
                      <Form.Control
                        type="text"
                        value={newIncentive.provider_link}
                        onChange={(e) =>
                          setNewIncentive({ ...newIncentive, provider_link: e.target.value })
                        }
                      />
                    </Form.Group>

                    <Button variant="primary" className="mt-3" onClick={handleCreateIncentive}>
                      Create Incentive
                    </Button>
                  </Card.Body>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ManageIncentives;
