import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

import { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MainNavbar from '../../Components/Navbars/MainNavbar';
import Footer from '../../Components/Footer';

import { Link } from 'react-router-dom';


import {CardGroup, Card, Container, Row, Col} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

const Home = () => {
    return (
        <Container fluid={true}  >

            <MainNavbar/>
            <Row>

            
                <Row>
                    <div class="container col-xxl-8 px-2 py-3">
                    
                    </div>
                            
                </Row>
                <Row>
                        <div class="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center"> 
                            <span className="text-center">
                                <h4>Product Offerings</h4>
                                <p>EV Charging Solutions for Multi-Family, Commercial, and Public Locations. Transform EV charging with our smart platform that aligns costs with your earnings. Benefit from advanced load balancing, transparent pricing, and real-time management, ensuring profitability and sustainability.</p>
                            </span>
                        </div>
                            
                </Row>
                <Row>
                    <div class="container col-xxl-8 px-3 py-3  d-flex align-items-center justify-content-center">
                        <div >

                            <CardGroup >
                                <Card className="mx-1 align-items-center py-3 px-3" >
                                    <Card.Img variant="top" src="images/front_page_evowner.webp" />
                                    <Card.Body>
                                        <Card.Title>EV Owners</Card.Title>
                                        <Card.Text>
                                        Ready to charge smarter?   Lets Charge ahead with convenience—find and book your nearest EV charger now!
                                        </Card.Text>
                                        <Link to="ev-drivers/why-us">
                                            <Button variant="primary">Explore Owner Benefits</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>                        
                            

                            
                                <Card className="mx-1 align-items-center py-3 px-3" >
                                    <Card.Img variant="top" src="images/front_page_parking.webp" />
                                    <Card.Body>
                                        <Card.Title>Multi-Family & Parking Owners</Card.Title>
                                        <Card.Text>
                                        Transform your parking space into a profit center today—partner with us to drive the future of electric mobility!
                                        </Card.Text>
                                        <Link to="business-owners/why-us-solutions">
                                            <Button variant="primary">Explore Property Manager Benefits</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>                        
                            

                            
                                <Card className="mx-1 align-items-center py-3 px-3" >
                                    <Card.Img variant="top" src="images/front_page_partner.webp" />
                                    <Card.Body>
                                        <Card.Title>Rebates & Incentives</Card.Title>
                                        <Card.Text>
                                        Empowering businesses to make the switch to electric mobility—explore our incentives and rebates today!
                                        </Card.Text>
                                        <Link to="incentives/usa-map">
                                            <Button variant="primary">Rebates & Incentives</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>                        
                            </CardGroup>
                                        
                        </div>
                    </div>
                </Row>  
            </Row>


            <Row>

            
                <Row>
                    <div class="container col-xxl-8 px-2 py-3">
                    
                    </div>
                            
                </Row>
                <Row>
                        <div class="container col-xxl-8 px-4 py-15 d-flex align-items-center justify-content-center"> 
                            <span className="text-center">
                                <h4>Potential Market by the Numbers</h4>
                                <p>As the world accelerates towards a more sustainable future, the electric vehicle (EV) market is experiencing unprecedented growth. Our platform taps into this expanding industry with advanced solutions that cater to an increasing demand for efficient and accessible EV charging. Explore the tremendous market potential and how you can capitalize on the shift towards electric mobility.</p>
                            </span>
                        </div>
                            
                </Row>
                <Row>
                    <div class="container col-xxl-8 px-3 py-3  d-flex align-items-center justify-content-center">
                        <div >

                            <CardGroup >
                                <Card className="mx-1 align-items-center py-3 px-3" >
                                    <Card.Img variant="top" src="images/sales.svg" />
                                    <Card.Body>
                                        <Card.Title>EV Sales Growth</Card.Title>
                                        <Card.Text>
                                        Experience the surge—EV sales are projected to rise dramatically, representing a burgeoning market for charging services.
                                        </Card.Text>
                                        
                                    </Card.Body>
                                </Card>                        
                            

                            
                                <Card className="mx-1 align-items-center py-3 px-3" >
                                    <Card.Img variant="top" src="images/totalEVs.svg" />
                                    <Card.Body>
                                        <Card.Title>Installed Base of EVs</Card.Title>
                                        <Card.Text>
                                        The growing installed base of EVs presents continuous opportunities for charging networks to expand and profit.
                                        </Card.Text>
                                        
                                    </Card.Body>
                                </Card>                        
                            

                            
                                <Card className="mx-1 align-items-center py-3 px-3" >
                                    <Card.Img variant="top" src="images/TAM.svg" />
                                    <Card.Body>
                                        <Card.Title>Total Market Size for EV Charging</Card.Title>
                                        <Card.Text>
                                        Tap into a burgeoning industry—total market size for EV charging is expected to skyrocket, promising significant returns for early investors.
                                        </Card.Text>
                                        
                                    </Card.Body>
                                </Card>                        
                            </CardGroup>
                                        
                        </div>
                    </div>
                </Row>  
            </Row>


            <Row>
                <div class="container col-xxl-8 px-3 py-3  d-flex align-items-center justify-content-center">
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div class="col-10 col-sm-8 col-lg-6">
                        <img src="/images/front_page_operational_control.webp" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
                        </div>
                        <div class="col-lg-6">
                            <h3 class="display-6 fw-bold lh-4 mb-3">Complete Operational Control</h3>
                            <p class="lead">Empower your business with full control over your EV charging stations. From easy registration to flexible, time-based pricing, manage every aspect seamlessly. Our platform supports intelligent load balancing to optimize efficiency and prevent overload, ensuring smooth operations without the hassle.</p>
 
                        </div>
                    </div>
                </div>
            </Row>  

            <Row>
                <div class="container col-xxl-8 px-3 py-3  d-flex align-items-center justify-content-center">
                    <div class="row align-items-center g-5 py-5">
                        <div class="col-10 col-sm-8 col-lg-6">
                        <img src="/images/front_page_security.webp" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
                        </div>
                        <div class="col-lg-6">
                            <h3 class="display-6 fw-bold lh-4 mb-3">Enhanced Security and Asset Management</h3>
                            <p class="lead">Secure your investment with robust security features and asset tracking capabilities. Stay ahead of potential equipment theft with real-time alerts and detailed asset management tools, ensuring your infrastructure is protected and traceable at all times.</p>
 
                        </div>
                    </div>
                </div>
            </Row>              

            <Row>
                <div class="container col-xxl-8 px-3 py-3  d-flex align-items-center justify-content-center">
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div class="col-10 col-sm-8 col-lg-6">
                        <img src="/images/front_page_ease2.webp" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
                        </div>
                        <div class="col-lg-6">
                            <h3 class="display-6 fw-bold lh-4 mb-3">Navigate Your Charge with Ease</h3>
                            <p class="lead">Experience ultimate convenience with our user-friendly platform designed for every EV driver. Effortlessly locate available chargers using our interactive map, complete with real-time status updates and transparent pricing. Whether through our easy-to-navigate mobile app or our no-download browser application, access to power is just a tap away. Simplify your charging journey with us and stay powered wherever you go!</p>
 
                        </div>
                    </div>
                </div>
            </Row>  

                        
            <Footer/>
        </Container>

    );
};

export default Home;

