import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  ComposableMap,
  Geographies,
  Geography
} from 'react-simple-maps';
import MainNavbar from '../../../Components/Navbars/MainNavbar';
import Footer from '../../../Components/Footer';


const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

function convertFipsToStateAbbrev(fips) {
  const fipsMap = {
    "01": "AL","02": "AK","04": "AZ","05": "AR","06": "CA","08": "CO","09": "CT",
    "10": "DE","11": "DC","12": "FL","13": "GA","15": "HI","16": "ID","17": "IL",
    "18": "IN","19": "IA","20": "KS","21": "KY","22": "LA","23": "ME","24": "MD",
    "25": "MA","26": "MI","27": "MN","28": "MS","29": "MO","30": "MT","31": "NE",
    "32": "NV","33": "NH","34": "NJ","35": "NM","36": "NY","37": "NC","38": "ND",
    "39": "OH","40": "OK","41": "OR","42": "PA","44": "RI","45": "SC","46": "SD",
    "47": "TN","48": "TX","49": "UT","50": "VT","51": "VA","53": "WA","54": "WV",
    "55": "WI","56": "WY","72": "PR"
  };
  const fipsPadded = String(fips).padStart(2, '0');
  return fipsMap[fipsPadded] || null;
}

const UsaMapPage = () => {
  const navigate = useNavigate();

  const handleStateClick = (geo) => {
    const fipsCode = geo.id;
    const stateAbbrev = convertFipsToStateAbbrev(fipsCode);
    if (stateAbbrev) {
      navigate(`/incentives/${stateAbbrev}`);
    } else {
      console.warn("No matching state for FIPS:", fipsCode);
    }
  };

  return (
    <Container fluid>
      <MainNavbar />
      <Row className="justify-content-center my-5">
        <Col md={10}>
          <h2 className="mb-4 text-center">Explore EV Incentives by State</h2>
          <p className="lead text-center">
            Hover over a state to highlight it. Click to view local incentives.
          </p>
          {/* 
            Wrap the map in a column that sets a maxWidth so it doesn’t stretch too large.
          */}
          <div className="d-flex justify-content-center">
            <div style={{ maxWidth: '800px', width: '100%' }}>
              <ComposableMap
                projection="geoAlbersUsa"
                width={800}
                height={500}
              >
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        onClick={() => handleStateClick(geo)}
                        style={{
                          default: {
                            fill: "#D6D6DA",
                            outline: "none",
                            cursor: "pointer"
                          },
                          hover: {
                            fill: "#F53",
                            outline: "none",
                            cursor: "pointer"
                          },
                          pressed: {
                            fill: "#E42",
                            outline: "none",
                            cursor: "pointer"
                          }
                        }}
                      />
                    ))
                  }
                </Geographies>
              </ComposableMap>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default UsaMapPage;
