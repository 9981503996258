import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import MainNavbar from '../../../Components/Navbars/MainNavbar';
import Footer from '../../../Components/Footer';
import getEnvConfig from '../../../config';
import Nav from 'react-bootstrap/Nav';

const { backendUrl } = getEnvConfig();

const StateIncentivesPage = () => {
  const { stateCode } = useParams();
  const [incentives, setIncentives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIncentives = async () => {
      try {
        const response = await fetch(`${backendUrl}/incentives/${stateCode}`);
        if (!response.ok) {
          if (response.status === 404) {
            // "No incentives found" => empty array
            setIncentives([]);
          } else {
            const data = await response.json();
            throw new Error(data.detail || 'Error fetching incentives');
          }
        } else {
          // OK => parse
          const data = await response.json();
          setIncentives(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchIncentives();
  }, [stateCode]);

  if (loading) {
    return (
      <Container fluid>
        <MainNavbar />
        <Row className="justify-content-center my-5">
          <Col md={10}>
            <p>Loading incentives for {stateCode}...</p>
          </Col>
        </Row>
        <Footer />
      </Container>
    );
  }

  if (error) {
    // Check if it's a 404 (or some message indicating no incentives found)
    // But in general, for any error, we'll display an error message with the nav & footer
    return (
      <Container fluid>
        <MainNavbar />
        <Row className="justify-content-center my-5">
          <Col md={10}>
            <h3>Error</h3>
            <p>{error}</p>
          </Col>
        </Row>
        <Footer />
      </Container>
    );
  }

  // After loading is done and there's no error, check if we have incentives
  const isEmpty = incentives.length === 0;

  return (
    <Container fluid>
      <MainNavbar />

      <Row className="justify-content-center my-5">
        <Col md={10}>
          <h2 className="mb-4 text-center">EV Incentives in {stateCode}</h2>

          {isEmpty ? (
            <Row className="mb-4">
              <Col>
                <div className="text-center p-4 border rounded bg-light">
                  <h4>No Current Incentives</h4>
                  <p className="lead">
                    We’re working hard to find rebates and incentives for this state. 
                    Please check back later or contact us for the latest info.
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            // Display the incentives rows
            incentives.map((item, index) => {
              let points = [];
              if (item.talking_points) {
                points = item.talking_points.split('|');
              }

              const imageIndex = index < 20 ? index + 1 : 1;
              const imagePath = `/images/rebate${imageIndex}.webp`;

              const rowClass =
                index % 2 === 0
                  ? 'row flex-lg-row-reverse align-items-center g-5 py-5'
                  : 'row align-items-center g-5 py-5';

              return (
                <Row className="mb-4" key={item.incentive_id}>
                  <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
                    <div className={rowClass}>
                      <div className="col-10 col-sm-8 col-lg-6">
                        <img
                          src={imagePath}
                          className="d-block mx-lg-auto img-fluid"
                          style={{ maxWidth: '100%', maxHeight: '450px' }}
                          alt={`Rebate ${imageIndex}`}
                          loading="lazy"
                        />
                      </div>

                      <div className="col-lg-6">
                        <h3 className="display-6 fw-bold lh-4 mb-3">
                          {item.incentive_name}
                        </h3>

                        {item.is_active ? (
                          <span className="badge bg-success">Active</span>
                        ) : (
                          <span className="badge bg-danger">Expired</span>
                        )}

                        <p className="lead mt-3">{item.incentive_description}</p>

                        {points.length > 0 && (
                          <ul>
                            {points.map((point, idx) => (
                              <li key={idx}>{point}</li>
                            ))}
                          </ul>
                        )}

                        <Button
                          variant="primary"
                          href={item.provider_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mb-3"
                        >
                          Learn More
                        </Button>

                        <hr />

                        <p className="lead">
                          Interested in leveraging this incentive?
                          <br />
                          <Button
                          variant="secondary"
                          href='/business-owners/support'
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mb-3"
                        >
                          Contact Us
                        </Button>


  
                        </p>
                      </div>
                    </div>
                  </div>
                </Row>
              );
            })
          )}
        </Col>
      </Row>

      <Footer />
    </Container>
  );
};

export default StateIncentivesPage;
