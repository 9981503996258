import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, { useState } from 'react';
import { clearToken, isUserLoggedIn } from '../../../Auth';


function MainNavbar() {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownClick = (dropdown) => {
    console.log(dropdown);
    setActiveDropdown(dropdown);
  };

  const handleLogout = () => {
    clearToken();
    window.location.reload(); // Refresh the page to update the UI
  };

  const loggedIn = isUserLoggedIn();

  return (
    <Navbar expand="sm" bg="webprimary"  variant="dark" className='bg-body-tertiary p-1'>
      <Container fluid={true}>
    
          
            <Navbar.Brand href="/">
              <img src="/images/evchron_logo3.png" width="70" height="65" alt="EV Chron"/>
              
            </Navbar.Brand>          
          
            <Nav.Link href="/" ><p className="navlinkText">EV Chron</p></Nav.Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />
            <Navbar.Collapse id="basic-navbar-nav"  >
              <Nav className="ms-auto" >
                <Nav.Link href="/" ><p className="navlinkText">Home</p></Nav.Link>
                
                <NavDropdown title="EV Drivers" id="basic-nav-dropdown-drivers" className={`navlinkText ${activeDropdown === 'evDrivers' ? 'active-dropdown' : 'navlinkText'}`}   onClick={() => handleDropdownClick('evDrivers')}>
                  <Nav.Link href="#home" ><p className="navlinkText">EV Drivers</p></Nav.Link>
                  <NavDropdown.Item href="/ev-drivers/why-us">Why us</NavDropdown.Item>
                  <NavDropdown.Item href="/ev-drivers/search-charging-stations">Search for Charging Stations</NavDropdown.Item>
                  
                  <NavDropdown.Item href='/ev-drivers/resources-guidances'>Resources & Guidance</NavDropdown.Item>
                  <NavDropdown.Item href="/ev-drivers/support">Support</NavDropdown.Item>
                </NavDropdown>
                
                <NavDropdown title="Business Owners" id="basic-nav-dropdown-business" className={`navlinkText ${activeDropdown === 'businessOwners' ? 'active-dropdown' : ''}`}
              onClick={() => handleDropdownClick('businessOwners')}>
                <Nav.Link href="#home" ><p className="navlinkText">Business Owners</p></Nav.Link>
                  <NavDropdown.Item href="/business-owners/why-us-solutions">Why us / Solutions?</NavDropdown.Item>
                  <NavDropdown.Item href="/incentives/usa-map">EV Rebates & Incentives</NavDropdown.Item>
                  <NavDropdown.Item href="/business-owners/resources-guidances">Resources & Guidance</NavDropdown.Item>
                  <NavDropdown.Item href="/business-owners/support">Support</NavDropdown.Item>
                </NavDropdown>           
                
                <NavDropdown title="About Us" id="basic-nav-dropdown-aboutus" className={`navlinkText ${activeDropdown === 'aboutUs' ? 'active-dropdown' : ''}`}
              onClick={() => handleDropdownClick('aboutUs')}>
                 <Nav.Link href="#home" ><p className="navlinkText">About Us</p></Nav.Link>
                  <NavDropdown.Item href="/about-us/who-we-are">Who are we</NavDropdown.Item>

                </NavDropdown>          

                {
                  loggedIn ? 
                  (
                    <Nav.Link onClick={handleLogout}><p className="navlinkText">Logout</p></Nav.Link>
                  ) : (
                    <Nav.Link href="/signin"><p className="navlinkText">Sign In</p></Nav.Link>
                  )
                }

{
                  loggedIn ? 
                  (
                    <Nav.Link href='/users/user-profile'><p className="navlinkText">User Area</p>
                    </Nav.Link>
                  ):(
                    <Nav.Link href='/signup'><p className="navlinkText"></p>
                    </Nav.Link>
                  )
                }
                
              </Nav>
            </Navbar.Collapse>

     


      </Container>
    </Navbar>
  );
}

export default MainNavbar;